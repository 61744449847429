<template>
  <b-container>
    <b-row class="m-1 justify-content-lg-center align-items-center">
      <b-col
          xl="10"
          lg="10"
          md="12"
          sm="12"
          class="d-flex align-items-center p-0 pt-md-4"
      >
        <router-view/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {BCol, BContainer, BRow} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BContainer,
    BRow,
  },
}
</script>
